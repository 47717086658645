import Portfolio from "./components/portfolio";

function App() {
  return (
    <html>
      <Portfolio />
    </html>
  );
}

export default App;
